@tailwind utilities;

@layer utilities {
  .striped-background {
    background: repeating-linear-gradient(
      to right,
      theme('colors.primary[500]'),
      theme('colors.primary[500]') 10px,
      theme('colors.primary[600]') 10px,
      theme('colors.primary[600]') 20px
    );
  }
  .pseudoSemibold:after {
    /**
     * Use this trick: https://css-tricks.com/bold-on-hover-without-the-layout-shift/
     * to prevent layout shifting when bolding text on hover
     */
    content: attr(data-text);
    @apply block h-0 invisible overflow-hidden pointer-events-none;
    /* Padding is needed as sometimes semibold text is narrower than normal text */
    @apply font-semibold px-[1px];
  }
  @media speech {
    .pseudoBold:after {
      display: none;
    }
  }
  .topScrollShadow {
    background: radial-gradient(farthest-side at 50% 0, rgba(102, 102, 102, 0.5), rgba(0, 0, 0, 0));
  }
  .bottomScrollShadow {
    background: radial-gradient(farthest-side at 50% 100%, rgba(102, 102, 102, 0.5), rgba(0, 0, 0, 0));
  }
  .fv-outline-internal {
    @apply focus-visible:-outline-offset-2;
  }
}
