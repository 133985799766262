@tailwind components;

@layer components {
  .slider {
    margin-top: 12px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .slider-track,
  .double-slider-track {
    height: 8px;
    @apply rounded-full;
    @apply bg-gray-50;
  }
  .slider-track.slider-track-0,
  .double-slider-track.double-slider-track-1 {
    @apply bg-primary-700;
  }
  .slider-thumb {
    width: 33px;
    height: 33px;
    margin-top: -12px;
    border: 7px solid white;
    outline: none;
    @apply bg-primary-300 rounded-full shadow;
  }
  .crop-slider {
    cursor: pointer;
  }
  .crop-slider-thumb {
    width: 24px;
    height: 24px;
    margin-top: -11px;
    margin-left: -11px;
    border: 6px solid white;
    @apply bg-primary-300 rounded-full shadow;
    @apply outline-offset-2;
  }
  .crop-slider-track {
    height: 2px;
    @apply bg-primary-300;
    @apply rounded-full;
  }
  .crop-slider-mark {
    width: 2px;
    height: 16px;
    @apply bg-primary-300;
    @apply rounded-full;
  }
}
