@tailwind base;

@layer base {
  * {
    /* Avoid content from being hidden under navigation bar when using scrollIntoView() */
    scroll-margin-top: 115px;
  }

  html {
    /* This makes it easier to work with rem units. E.g. 1.6rem means 16px. */
    font-size: 10px;
  }

  body {
    @apply text-base;
    @apply text-primary-800;
  }

  @media screen {
    html {
      height: 100%;
    }
    body {
      height: 100%;
    }
    #__next {
      height: 100%;
    }
  }

  body.ReactModal__Body--open {
    overflow: hidden;
  }

  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }

  ul {
    @apply list-disc;
    @apply list-outside;
    @apply marker:text-primary-300;
    @apply pl-8;
  }

  ol {
    @apply list-decimal;
    @apply list-outside;
    @apply marker:text-primary-300;
    @apply pl-8;
  }

  label {
    @apply text-sm;
    @apply font-semibold;
  }

  input {
    @apply text-center;
  }

  input,
  textarea {
    min-height: 4.4rem;
    @apply outline-none;
    @apply border-gray-100;
    @apply border-thin;
    @apply rounded-[10px];
    @apply px-4 py-2;
    @apply placeholder-gray-200;
  }

  input:not([disabled]):not(.error):not(:invalid),
  textarea:not([disabled]):not(.error):not(:invalid) {
    @apply hover:border-primary-300;
    @apply focus:border-primary-300;
    @apply focus-visible:border-primary-300;
  }

  input:invalid,
  input.error,
  textarea:invalid,
  textarea.error {
    @apply border-red-200;
    @apply focus:border-red-200;
  }

  a {
    @apply text-primary-600;
    @apply hover:text-shadow;
  }

  a.external-link:after,
  a .external-link:after {
    @apply inline-block;
    @apply w-[14px] h-[14px];
    @apply text-primary-600;
    @apply bg-center bg-no-repeat bg-contain;
    margin-left: 0.1em;
    content: '';
    background-image: url('/img/arrow-top-right-on-square.svg');
  }

  .text-shadow {
    text-shadow: 0 0 1px;
  }
  .no-text-shadow {
    text-shadow: none;
  }

  button:focus-visible,
  a:focus-visible,
  .outline-focus-visible {
    @apply outline;
    @apply outline-2;
    @apply outline-blue-500;
    @apply outline-offset-0;
  }

  @font-face {
    font-family: 'HK Grotesk';
    font-weight: 400;
    src: url('/fonts/HKGrotesk-Regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'HK Grotesk';
    font-weight: 500;
    src: url('/fonts/HKGrotesk-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'HK Grotesk';
    font-weight: 600;
    src: url('/fonts/HKGrotesk-SemiBold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'HK Grotesk';
    font-weight: 700;
    src: url('/fonts/HKGrotesk-Bold.woff2') format('woff2');
  }
}
